"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function InitOriginalLongDrink() {
    window.closeLanguage = function () {
        $("#page-wide-language-wrapper").removeClass("display-language-field");
        $("body").removeClass("u-ofh");
        $(".main-language-button i").html("language");
        $(".o-mobile-menu").removeClass("u-fixed s-themecolor-1");
        $(".o-navigation").removeClass("s-themecolor-1");
    };
    window.openLanguage = function () {
        $("#page-wide-language-wrapper").addClass("display-language-field");
        $("body").addClass("u-ofh");
        $(".main-language-button i").html("close");
        $(".o-mobile-menu").addClass("u-fixed s-themecolor-1");
        $(".o-navigation").addClass("s-themecolor-1");
    };
    $('document').ready(function () {
        // Add relative class on ".content-wrapper"
        if ($(".o-history-section").length) {
            $(".content-wrapper").addClass("u-relative");
            // Add absolute class on ".o-history-section" when footer comes in view
            $(window).on('scroll', function () {
                if ($(window).scrollTop() >= $('.o-history-section').offset().top + $('.o-history-section').outerHeight() + (50) - window.innerHeight) {
                    $(".o-history-selected").addClass("o-history-selected--is-absolute");
                }
                else {
                    $(".o-history-selected").removeClass("o-history-selected--is-absolute");
                }
            });
        }
        ;
        var holdPeriod = false;
        var wikiNavigationTop = $('.wiki-navigation').length ? $('.wiki-navigation').offset().top : 0;
        $(".js-wiki-top-page").on('click', function (event) {
            if ($(this).closest('.wiki-page').hasClass('current-page')) {
                event.preventDefault();
                $('html, body').animate({
                    scrollTop: 0
                }, 800);
            }
            else {
                $('.o-wiki-page').css('opacity', '.1');
            }
        });
        $(".js-anchorLink").on('click', function (event) {
            $('.wiki-page a').removeClass('active');
            $(this).addClass('active');
            holdPeriod = true;
            setTimeout(function () { holdPeriod = false; }, 1000);
            if ($(this).closest('.wiki-page').hasClass('current-page')) {
                event.preventDefault();
                var scrollto = $(this).attr('data-content-pointer');
                $('html, body').animate({
                    scrollTop: ($("[data-content-id= " + scrollto + "]").offset().top - 30)
                }, 800);
            }
            else {
                console.log('wiki-page load');
                $('.o-wiki-page').css('opacity', '.1');
            }
        });
        $(".wiki-anchor-link > a").on('click', function (e) {
            e.preventDefault();
            var scrollto = $(this).attr('data-content-pointer');
            $('html, body').animate({
                scrollTop: ($("[data-content-id= " + scrollto + "]").offset().top - 30)
            }, 800);
        });
        $(window).on('scroll', function () {
            if (holdPeriod == false) {
                $('[data-content-id]').each(function () {
                    if ($(window).scrollTop() + ($(window).height() / 4) >= $(this).offset().top) {
                        var id = $(this).attr('data-content-id');
                        //console.log(id + " - " + $(this).offset().top);
                        $('.wiki-page a').removeClass('active');
                        $('.wiki-page a[data-content-pointer=' + id + ']').addClass('active');
                    }
                });
            }
            //Wiki: Reposition the navigation to the top after scrolling beyond the top bar.
            if ($(window).scrollTop() > (wikiNavigationTop - 30)) {
                $('.wiki-navigation > ul').css({ top: '0px' });
            }
            else {
                $('.wiki-navigation > ul').css({ top: '' });
            }
        });
        console.log('navigation height: ' + $('.wiki-navigation > ul').height());
        if ($('.wiki-navigation').length && $('.wiki-navigation > ul').height() > $(window).height() - 100) {
            console.log('window too small, navigation cannot be fixed');
            $('.wiki-navigation > ul').css('position', 'unset');
        }
        $('.o-wiki-bottom-bar__link a, o-wiki-bottom-bar__link a').on('click', function () {
            $('.o-wiki-page').css('opacity', '.1');
        });
        // detecting navigation between React views
        function onUrlChange() {
            setTimeout(function () {
                // Fix to product view navigation
                if ($('.o-product-display').length) {
                    $('.o-navigation-wrapper').removeClass('u-absolute');
                }
            }, 400);
        }
        var lastUrl = location.href;
        new MutationObserver(function () {
            var url = location.href;
            if (url !== lastUrl) {
                lastUrl = url;
                onUrlChange();
            }
        }).observe(document, { subtree: true, childList: true });
    });
}
exports.InitOriginalLongDrink = InitOriginalLongDrink;
