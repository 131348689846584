"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// Polyfills for IE. Important
require("./polyfills");
require("@babel/polyfill");
require('select2');
require('./../scss/sites/originallongdrink/originallongdrink.scss');
var index_1 = require("./../js/index");
var originallongdrink_1 = require("./../js/Sites/originallongdrink");
// Initialize scripts that are used in all websites
index_1.Init();
originallongdrink_1.InitOriginalLongDrink();
// Import styles and scripts included in coreweb packages
index_1.ImportCoreweb();
// Initialize and mount react components
index_1.InitReact();
$(document).ready(function () {
    $("body").on("click", ".play-audio", function (e) {
        e.preventDefault();
        var audioPlayer = e.currentTarget.parentNode.firstElementChild;
        audioPlayer.play();
    });
    $("body").on("click", ".play-video", function (e) {
        e.preventDefault();
        var videoId = $(this).data("video-id");
        var iframeId = $(this).data("iframe-id");
        var hideControls = $(this).data("hide-controls");
        var videoElem = document.getElementById("video-" + videoId);
        var iframeElem = document.getElementById("iframe-" + iframeId);
        if (videoElem) {
            videoElem.controls = hideControls !== "True";
            videoElem.play();
            $(this).closest(".o-video-thumbnail").hide();
        }
        else if (iframeElem) {
            $(iframeElem).show();
            $(this).closest(".o-video-thumbnail").hide();
        }
    });
    $("body").on("click", ".o-scroll-hero .o-round-button", function (e) {
        $("html, body").animate({ scrollTop: $(window).height() });
    });
    //Initialize select2 for Happyjoe Contact form
    $('.Form__Element.FormSelection select').hide();
    $('.Form__Element.FormSelection select').select2({
        minimumResultsForSearch: Infinity,
        width: 'resolve',
        dropdownParent: $('.Form__MainBody')
    });
    $('.o-history-event').hover(function () {
        var history_event_id = $(this).attr('data-event');
        $(".bg-image").removeClass("is-active");
        $("." + history_event_id).addClass("is-active");
        //console.log('hover' + $(this).attr('data-background'));
        if ($(this).data('dimfilter') === "True") {
            $('.js-dimfilter').show();
        }
        //$('.o-history-selected .bg-image.bg-hires').css('background-image', 'url(' + $(this).attr('data-background') + '?width=' + Math.ceil($('.o-history-selected').width()) + '&height=' + Math.ceil($('.o-history-selected').height()) + '&mode=crop), url(' + $(this).attr('data-background') + '?width=' + Math.ceil($('.o-history-selected').width() / 100) + '&height=' + Math.ceil($('.o-history-selected').height() / 100) + '&mode=crop)');
        //$('.o-history-selected .bg-image.bg-hires').css('background-image', 'url(' + $(this).attr('data-background') + '?width=' + Math.ceil($('.o-history-selected').width()) + '&height=' + Math.ceil($('.o-history-selected').height()) + '&mode=crop)');
        //$('.o-history-selected .bg-image.bg-lores').css('background-image', 'url(' + $(this).attr('data-background') + '?width=' + Math.ceil($('.o-history-selected').width() / 100) + '&height=' + Math.ceil($('.o-history-selected').height() / 100) + '&mode=crop)');
        $('.o-history-section').addClass('active');
        $('.o-history-event').each(function () {
            $(this).removeClass('active');
        });
        $('.o-history-folders div').each(function () {
            $(this).removeClass('active');
        });
        $(this).addClass('active');
        $('.o-history-folders [data-folder="' + $(this).attr('data-folder') + '"]').addClass('active');
    });
    $('.o-history-gindex').hover(function () {
        $(".bg-image").removeClass("is-active");
    });
    $('.o-history-section, .o-history-event').mouseleave(function () {
        $('.o-history-event').each(function () {
            $(this).removeClass('active');
        });
        $(this).removeClass('active');
    });
    $('.o-history-event').mouseleave(function () {
        //$('.o-history-selected .bg-image.bg-hires').css('background-image', 'none');
        //$('.o-history-selected .bg-image.bg-lores').css('background-image', 'none');
        $('.js-dimfilter').hide();
    });
});
// Import site specific scripts
//require("./Sites/originallongdrink");
